var winX = $(window).innerWidth(),
	winY = $(window).innerHeight(),
	rem = 18,
	mobi = 576, tabl = 768, desk = 992, lapt = 1280, port = 1440, land = 1920;
// Actualiza en valor de rem
function setRem(s){
	var winX = $(window).innerWidth();
	if(winX <= s)
	{
		return rem = 15;
	} else {
		return rem = 18;
	}
}
// Comprueba si dos números son múltiples entre sí
function isMultiple(n, m){
	return n % m == 0 && n != 0;
}
// Devuelve la longitud de 'e'
function getLength(e){
	return e.length;
}
// Comprueba si el alto del viewport es menor que 'n'
function isShorterY(n){
	var y = $(window).innerHeight();
	return y <= n;
}
// Comprueba si el ancho del viewport es menor que 'n'
function isShorterX(n){
	var x = $(window).innerWidth();
	return x <= n;
}
// Devuelve el valor de la altura exterior de 'd'
function getOuterHeight(d){
	return d.outerHeight();
}
// Devuelve el valor de la altura interior de 'd'
function getInnerHeight(d){
	return d.innerHeight();
}
// Devuelve el valor de la anchura exterior de 'd'
function getOuterWidth(d){
	return d.outerWidth();
}
// Devuelve el valor de la anchura interior de 'd'
function getInnerWidth(d){
	return d.innerWidth();
}
// Devuelve el valor de la altura de 'd'
function getHeight(d){
	return d.height();
}
// Devuelve el valor de la anchura de 'd'
function getWidth(d){
	return d.width();
}
// Comprueba si una URL contiene 'r'
function isUrl(r){
	return window.location.href.indexOf(r) > 0;
}
// Devuelve la URL
function getUrl() {
	return $(location).attr('href');
}
// Devuelve el estado :hover de un elemento
function isHover(d){
	return d.find($(this)+':hover').length > 0;
}
// Comprueba si un elemento dado contiene una string determinada en su atributo 'src'.
function isSrc(e, o){
	return $(''+e+'[src*="'+o+'"]').length > 0;
}
